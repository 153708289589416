import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About Us | Відкрийте для себе Творчі Петлі
			</title>
			<meta name={"description"} content={"Зануртесь у світ рукоділля та створіть власну унікальну історію з Творчими Петлями"} />
			<meta property={"og:title"} content={"About Us | Відкрийте для себе Творчі Петлі"} />
			<meta property={"og:description"} content={"Зануртесь у світ рукоділля та створіть власну унікальну історію з Творчими Петлями"} />
			<meta property={"og:image"} content={"https://nulvorich.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nulvorich.com/img/4634647.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nulvorich.com/img/4634647.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nulvorich.com/img/4634647.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-18"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="4px 0px 12px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					Про нас
				</Text>
				<Text
					margin="8px 0px 0px 0px"
					font="--lead"
					text-align="center"
					color="--darkL2"
					width="90%"
					lg-width="100%"
					sm-text-align="left"
				>
					Творчі Петлі не просто місце, де можна навчитися в'язати чи вишивати. Це простір, де кожен може знайти щось для себе, розвинути свої здібності та втілити у життя найсміливіші ідеї. Наша місія – надихати вас на творчість, допомагати розкривати потенціал та підтримувати у цьому захоплюючому процесі.
					<br /><br />
					Творчі Петлі – це відображення творчої енергії та бажання ділитися нею з іншими. Ми прагнемо, щоб кожен, хто приєднується до наших курсів, відчував себе частиною великої творчої спільноти, де кожен може знайти підтримку, натхнення та дружнє оточення.
				</Text>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://nulvorich.com/img/5.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-light"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Чому варто обрати саме нас
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					Індивідуальний підхід: Кожен учень для нас особливий, і ми підбираємо матеріали та завдання з урахуванням особистих потреб та рівня майстерності.
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					Гнучкість та доступність: Наші онлайн-курси дають можливість навчатися у зручний для вас час, не виходячи з дому.
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					Спільнота однодумців: Приєднуючись до нас, ви стаєте частиною дружньої та творчої спільноти, де кожен готовий поділитися досвідом та підтримати один одного.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				grid-template-columns="4fr 5fr"
				align-items="center"
				width="50%"
				lg-width="100%"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://nulvorich.com/img/6.jpg"
					object-fit="cover"
					width="100%"
					height="80%"
					lg-height="100%"
				/>
				<Image
					src="https://nulvorich.com/img/7.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="460px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				padding="42px 42px 42px 42px"
				lg-width="100%"
				md-padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Створюйте свою історію разом з нами
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					Творчі Петлі запрошують вас не просто навчатися, а стати частиною нашої великої творчої історії. Тут кожен ваш вузлик і стібок стає частиною чогось більшого, дозволяючи вам залишити свій унікальний слід у світі рукоділля.
				</Text>
				<Link
					href="/contacts"
					color="--lightD1"
					text-decoration-line="initial"
					padding="11px 24px 12px 24px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					border-radius="36px"
					letter-spacing="1px"
					text-transform="uppercase"
				>
					Зв'яжіться з нами
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});